<template>
  <div class="tw-flex tw-flex-col tw-gap-3">
    <div class="tw-flex tw-items-center">
      <div class="tw-grow-0 tw-pr-4 tw-text-3xl tw-font-bold tw-tracking-wide">
        Dominating news topics
      </div>

      <div class="tw-grow tw-h-1 tw-bg-black" />
    </div>

    <div v-if="loading" class="tw-h-[171px]">
      Loading...
    </div>

    <div v-show="!loading" class="tw-flex tw-overflow-x-auto tw-space-x-3 tw-w-full">
      <div v-for="(event, index) in events" :key="index" class="tw-w-[325px] tw-flex-shrink-0 tw-p-4 tw-flex tw-flex-col tw-gap-4 tw-border tw-border-black">
        <div class="tw-text-xl tw-font-bold tw-tracking-wider tw-antialiased tw-line-clamp-2">
          {{ event.headline }}
        </div>
        <div class="tw-text-sm tw-font-normal tw-leading-6 tw-line-clamp-3">
          {{ event.abstract }}
        </div>
        <div class="tw-flex tw-flex-row tw-justify-end tw-gap-1 tw-truncate">
          <span v-for="(url, index) in event.urls" :key="url.domain">
            <a
              class="tw-text-sm tw-underline"
              :href="url.path"
              target="_blank"
            >{{ url.domain }}</a><span v-if="index + 1 < event.urls.length">,</span>
          </span>
        </div>
      </div>
    </div>

    <div class="tw-w-full tw-h-1 tw-bg-black" />
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false
    },

    events: {
      type: Array,
      required: true
    }
  }
}
</script>
